export const programs = [
  {
    name: 'Polskie radio&nbsp;24',
    lineColor: '#d51a35',
    href: 'https://polskieradio24.pl',
    links: [
      // {
      //   href: 'https://polskieradio24.pl/321/5517,Felietony',
      //   name: 'felietony',
      // },
      { href: 'https://polskieradio24.pl/antena/audycje', name: 'audycje' },
      {
        href: 'https://polskieradio24.pl/antena/zespol-antenowy',
        name: 'zespół',
      },
      { href: 'https://polskieradio24.pl/antena/ramowka', name: 'ramówka' },
      {
        href: 'https://polskieradio24.pl/antena/czestotliwosci',
        name: 'częstotliwości',
      },
      {
        href: 'https://polskieradio24.pl/antena/kontakt',
        name: 'kontakt',
      },
      // {
      //   href: 'https://polskieradio24.pl/321/5489,Newsletter',

      //   name: 'newsletter',
      // },
    ],
  },
  {
    name: 'Jedynka',
    lineColor: '#f68e1e',
    href: 'https://jedynka.polskieradio.pl',
    links: [
      { href: '/audycje', name: 'audycje' },
      { href: '/playlista', name: 'playlista' },
      { href: '/zespol', name: 'zespół' },
      { href: '/artykuly/169', name: 'konkursy' },
      { href: '/ramowka?current=JEDYNKA', name: 'ramówka' },
      { href: '/czestotliwosci', name: 'częstotliwości' },
      { href: '/kontakt', name: 'kontakt' },
    ],
  },
  {
    name: 'Dwójka',
    lineColor: '#009d63',
    href: 'https://polskieradio.pl/8,Dwojka',
    links: [
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/23,Audycje',
        name: 'audycje',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/26,Playlista',
        name: 'playlista',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/5660,Koncerty-w-dwojce',
        name: 'koncerty',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/25,Zespol',
        name: 'zespół',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/364,Konkursy',
        name: 'konkursy',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/6546,Patronaty',
        name: 'patronaty',
      },
      { href: 'https://www.polskieradio.pl/ramowka/antena/2', name: 'ramówka' },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/4940,Czestotliwosci',
        name: 'częstotliwości',
      },
      {
        href: 'https://www.polskieradio.pl/8,Dwojka/27,Kontakt',
        name: 'kontakt',
      },
    ],
  },
  {
    name: 'Trójka',
    lineColor: '#cc0471',
    href: 'https://trojka.polskieradio.pl',
    links: [
      {
        href: 'https://www.polskieradio.pl/9,Trojka/30,Audycje',
        name: 'audycje',
      },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/33,Playlista',
        name: 'playlista',
      },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/319,Koncert-w-Trojce',
        name: 'koncerty',
      },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/32,Zespol',
        name: 'zespół',
      },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/203,Konkursy-w-Trojce',
        name: 'konkursy',
      },
      { href: 'https://www.polskieradio.pl/ramowka/antena/3', name: 'ramówka' },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/4939,Czestotliwosci',
        name: 'częstotliwości',
      },
      {
        href: 'https://www.polskieradio.pl/9,Trojka/34,Kontakt',
        name: 'kontakt',
      },
    ],
  },
  {
    name: 'Czwórka',
    lineColor: '#fef200',
    href: 'https://polskieradio.pl/10,Czworka',
    links: [
      {
        href: 'https://www.polskieradio.pl/10,Czworka/37,Audycje',
        name: 'audycje',
      },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/40,Playlista',
        name: 'playlista',
      },
      {
        href: 'https://www.polskieradio.pl/10/5798,Koncerty',
        name: 'koncerty',
      },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/39,Zespol',
        name: 'zespół',
      },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/1365,Konkursy',
        name: 'konkursy',
      },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/1366,Patronaty',
        name: 'patronaty',
      },
      { href: 'https://www.polskieradio.pl/ramowka/antena/4', name: 'ramówka' },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/5496,Jak-nas-sluchac',
        name: 'Jak nas słychać?',
      },
      {
        href: 'https://www.polskieradio.pl/10,Czworka/41,Kontakt',
        name: 'kontakt',
      },
    ],
  },
  {
    name: 'KIEROWCY',
    lineColor: '#f4463e',
    href: 'https://radiokierowcow.pl',
    links: [],
  },
  {
    name: 'DZIECI',
    lineColor: '#cc1d34',
    href: 'https://polskieradio.pl/18,Dzieci',
    links: [],
  },
  {
    name: 'Chopin',
    lineColor: '#b3afb0',
    href: 'https://chopin.polskieradio.pl',
    links: [],
  },
  {
    name: 'Radio Poland',
    lineColor: '#c91a33',
    href: 'https://polskieradio.pl/395',
    links: [
      {
        href: 'https://www.polskieradio.pl/398',
        name: 'ПР для України',
      },
      {
        href: 'https://www.polskieradio.pl/395',
        name: 'English section',
      },
      {
        href: 'https://www.polskieradio.pl/396',
        name: 'БЕЛАРУСКАЯ СЛУЖБА',
      },
      {
        href: 'https://www.polskieradio.pl/397',
        name: 'РУССКАЯ СЛУЖБА',
      },
      {
        href: 'https://www.polskieradio.pl/399',
        name: 'REDAKCJA POLSKA',
      },
      {
        href: 'https://www.polskieradio.pl/400',
        name: 'DEUTSCHE REDAKTION',
      },
      {
        href: 'http://www.kolpolin.pl/7',
        name: 'HEBREW SECTION - ARCHIVE',
      },
    ],
  },

  {
    name: 'Redakcja katolicka',
    lineColor: '#ffffff',
    href: 'https://polskieradio.pl/37,Redakcja-Programow-Katolickich',
    links: [],
  },
  {
    name: 'Redakcja Ekumeniczna',
    lineColor: '#9be4ff',
    href: 'https://polskieradio.pl/196,Ekumenia',
    links: [],
  },
];
