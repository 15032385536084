import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { PlayerContext } from 'utils/PlayerContext';
import { Row, Col, Container } from 'react-grid-system';
import { motion } from 'framer-motion';
import {
  Socials,
  Box,
  ImageLink,
  Navbar,
  ButtonRounded,
  Link,
  Typography,
  SearchBar,
  HeaderDropdown,
} from 'components';

import {
  system,
  color,
  ColorProps,
  BackgroundColorProps,
  layout,
  LayoutProps,
} from 'styled-system';

import jedynka from 'public/logos/jedynka.svg';
import jedynkaInverted from 'public/logos/jedynka_inverted.svg';
import PR from 'public/logos/Portal_PR_logo.svg';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

// import { ColorMode, PaletteContext } from 'utils/paletteContext';
import { programs } from '../../Footer/externalNavigation/src';

interface ILine extends ColorProps, BackgroundColorProps {
  lineColor: string;
  backgroundColor: string;
}

interface SeeMoreProps extends LayoutProps, ColorProps, BackgroundColorProps {
  pLeft: number;
  pRight: number;
  backgroundColor: string;
  color: string;
}

const LineText = styled.div<ILine>(
  color,
  system({
    color: {
      property: 'color',
      scale: 'palette',
    },
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
  }),
  ({ lineColor, backgroundColor }) => css`
    border-top: 2px solid ${lineColor};
    text-transform: uppercase;
    font-family: 'Fira Sans';
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${backgroundColor};
  `
);

const SeeMore = styled.button<SeeMoreProps>(
  layout,
  system({
    pLeft: {
      property: 'paddingLeft',
      scale: 'space',
    },
    pRight: {
      property: 'paddingRight',
      scale: 'space',
    },
    backgrounmdColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    color: {
      property: 'color',
      scale: 'palette',
    },
  }),
  ({ pLeft, pRight, backgroundColor, color }) => {
    return css<SeeMoreProps>`
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 50px;
      width: 50px;
      background-color: ${backgroundColor};
      color: ${color};
      border: none;
      padding-left: ${pLeft};
      padding-right: ${pRight};
      margin-right: 20px;

      cursor: pointer;
      &:hover {
        background-color: #242424;
        color: #f68e1e;
      }
    `;
  }
);

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: { opacity: 0, height: 0 },
};

const parseLink = (x: string) => {
  return x[0] === '/' ? `https://www.polskieradio.pl${x}` : x;
};

export const HeaderDesktop: React.FC = () => {
  const { pathname } = useRouter();
  const playerContext = React.useContext(PlayerContext);

  const [isScrolled, setScrolled] = React.useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [direction, setDirection] = React.useState<'up' | 'down'>('up');
  const [moreVisible, setMoreVisible] = React.useState<boolean>(false);
  const [navHovered, setNavHovered] = React.useState<string>('');

  const scrollBox = React.useRef<HTMLDivElement>(null);

  const isHomepage = pathname === '/';
  const animation =
    (!isHomepage && !open) || (isScrolled && !open) ? 'collapsed' : 'open';

  const navColor = (name: string, colorHovered: string, color: string) => {
    if (navHovered === '') {
      return name === 'Jedynka' ? colorHovered : color;
    }
    return navHovered === name ? colorHovered : color;
  };

  const bgColor = (name: string, lineColor: string) => {
    return name === 'Jedynka' &&
      programs?.find((program) => program.name === navHovered)?.links.length ===
        0
      ? 'main.primary'
      : navColor(name, lineColor, 'neutral.white');
  };

  const fontColor = (name: string) => {
    if (name === 'Czwórka') {
      return 'neutral.dark';
    }
    return name === 'Jedynka' &&
      programs?.find((program) => program.name === navHovered)?.links.length ===
        0
      ? 'neutral.light'
      : navColor(name, 'neutral.light', 'neutral.dark');
  };

  useEffect(() => {
    if (open) setOpen(false);
  }, [pathname]);

  const checkScroll: IntersectionObserverCallback = (elems) => {
    const [elem] = elems;
    setScrolled(!elem.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(checkScroll, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (scrollBox.current) observer.observe(scrollBox.current);

    return () => {
      if (scrollBox.current) observer.unobserve(scrollBox.current);
    };
  }, [scrollBox]);
  const noMargin =
    pathname.includes('/audycje/') || pathname.includes('/wyszukiwanie/');

  const openPlayer = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        isVisible: true,
        isPlaying: true,
        track: null,
        wasStreamPlaying: true,
      },
    });
  };

  // const { mode, setMode } = React.useContext(PaletteContext);

  return (
    <Box height={10}>
      <Box marginBottom={noMargin ? '122px' : '184px'} position="relative" />
      <Box
        backgroundColor="transparent"
        style={{ pointerEvents: 'none' }}
        width="1px"
        height="1px"
        position="sticky"
        left="50vw"
        top="100vh"
        ref={scrollBox}
        zIndex="999"
      />
      <Box
        width="100%"
        position="fixed"
        top={0}
        zIndex={4}
        backgroundColor="neutral.white"
      >
        <Box py={2}>
          <Container fluid md>
            <Box
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              height="73px"
              ml="-5px"
            >
              <Box width="30%" height="100%" display="flex">
                <ImageLink
                  alt="Logo radiowej Jedynki"
                  href="/"
                  imgOff={jedynka}
                  imgOn={jedynkaInverted}
                  target="_self"
                />
                <Box
                  border="2px solid"
                  height="100%"
                  borderColor="neutral.light"
                  mx={2}
                />

                <ImageLink
                  alt="Strona główna Polskiego Radia"
                  href="https://www.polskieradio.pl/"
                  imgOff={PR}
                />
              </Box>
              <Box
                width="450px"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box height="100%" width="33%">
                  <Socials />
                </Box>
                <Box width="140px">
                  <Link
                    href="https://podcasty.polskieradio.pl/antena/jedynka"
                    tabIndex={-1}
                  >
                    <ButtonRounded
                      color="main.tertiary"
                      decorator={<SVG src="/icons/mic.svg" />}
                    >
                      PODCASTY
                    </ButtonRounded>
                  </Link>
                </Box>
                <Box width="140px">
                  <ButtonRounded
                    color="main.secondary"
                    onClick={openPlayer}
                    isCircle
                    decorator={
                      <SVG
                        src="/icons/play_inverted.svg"
                        style={{ marginLeft: 0 }}
                      />
                    }
                  >
                    RADIO
                  </ButtonRounded>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <motion.div variants={variants} animate={animation}>
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              borderTop="1px solid"
              borderColor="neutral.gray"
              flex="1"
              marginRight="-16px"
            />
            <Container fluid md style={{ width: '100%' }}>
              <Row
                align="center"
                style={{ height: 50, marginLeft: 0, marginRight: 0 }}
              >
                <Col xs={8.5} style={{ height: '100%', padding: '0' }}>
                  <Box width="100%" display="flex">
                    {programs.map(
                      ({ name, lineColor, href, links }, index) =>
                        name !== 'Redakcja katolicka' &&
                        name !== 'Redakcja Ekumeniczna' && (
                          <Box
                            onMouseEnter={() => {
                              setNavHovered(name);
                            }}
                            onMouseLeave={() => {
                              setNavHovered('');
                            }}
                            height="50px"
                            key={name}
                            width={`${100 / (programs.length - 2)}%`}
                            borderLeft={index === 0 ? '1px solid' : ''}
                            borderRight="1px solid"
                            borderColor="neutral.gray"
                          >
                            <LineText
                              lineColor={lineColor}
                              color={fontColor(name)}
                              backgroundColor={bgColor(name, lineColor)}
                            >
                              <Link href={parseLink(href)}>
                                <span
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{ __html: name }}
                                />
                              </Link>
                            </LineText>
                            {links?.length !== 0 && (
                              <Box
                                zIndex="1000"
                                position="relative"
                                display={navHovered === name ? 'block' : 'none'}
                              >
                                <Navbar
                                  links={links}
                                  scrolled={isScrolled}
                                  isHomepage={isHomepage}
                                  setOpen={setOpen}
                                  open={open}
                                  setDirection={setDirection}
                                  direction={direction}
                                  backgroundColor={lineColor}
                                  color={
                                    name === 'Czwórka'
                                      ? 'neutral.dark'
                                      : 'neutral.white'
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                        )
                    )}
                  </Box>
                </Col>
                <Col xs={3.5} style={{ height: '100%', padding: '0' }}>
                  <Box
                    height="100%"
                    display="flex"
                    alignItems="center"
                    borderTop="1px solid"
                    borderColor="neutral.gray"
                  >
                    <SeeMore
                      onMouseEnter={() => {
                        setMoreVisible(true);
                      }}
                      onMouseLeave={() => {
                        setMoreVisible(false);
                      }}
                      pLeft={4}
                      pRight={4}
                      onClick={() => setMoreVisible(!moreVisible)}
                      backgroundColor={
                        moreVisible ? 'neutral.dark' : 'transparent'
                      }
                      color={moreVisible ? 'main.primary' : 'neutral.dark'}
                    >
                      <Typography
                        fontFamily="Fira Sans"
                        fontSize="28px"
                        fontWeight={800}
                        letterSpacing="2px"
                      >
                        ...
                      </Typography>
                      {moreVisible && <HeaderDropdown />}
                    </SeeMore>

                    <SearchBar />
                  </Box>
                </Col>
              </Row>
            </Container>
            <Box
              borderTop="1px solid"
              borderColor="neutral.gray"
              flex="1"
              marginLeft="-15px"
            />
          </Box>
        </motion.div>
        <Navbar
          scrolled={isScrolled}
          isHomepage={isHomepage}
          setOpen={setOpen}
          open={open}
          setDirection={setDirection}
          direction={direction}
          links={programs?.find((program) => program.name === 'Jedynka')?.links}
        />
      </Box>
    </Box>
  );
};

export const Header = HeaderDesktop;
export default Header;
